import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';

class AuthenticationService {
  updateTokenTimeoutId: number | null = null;
  minValidity = 15;
  keycloak: Keycloak;

  constructor(config: KeycloakConfig) {
    this.keycloak = new Keycloak(config);
    this.keycloak.onTokenExpired = this.handleUpdateToken;
    this.keycloak.onAuthRefreshError = this.handleAuthRefreshError;
  }

  clearUpdateTokenTimeoutId = () => {
    window.removeEventListener('online', this.handleUpdateToken);
    if (this.updateTokenTimeoutId) {
      clearTimeout(this.updateTokenTimeoutId);
      this.updateTokenTimeoutId = null;
    }
  };

  handleUpdateToken = () => {
    this.clearUpdateTokenTimeoutId();
    this.keycloak.updateToken(this.minValidity);
  };

  handleAuthRefreshError = () => {
    this.clearUpdateTokenTimeoutId();

    if (!navigator.onLine) {
      console.error('AuthRefreshError: navigator.onLine = ' + navigator.onLine + ' wait for online event');
      window.addEventListener('online', this.handleUpdateToken);
    } else {
      this.updateTokenTimeoutId = window.setTimeout(this.handleUpdateToken, 10000);
    }
  };

  init(options: KeycloakInitOptions = { onLoad: 'login-required', enableLogging: false }) {
    return this.keycloak.init({ ...options, pkceMethod: 'S256' });
  }

  login() {
    this.keycloak.login();
  }

  logout() {
    let pathname = window.location.pathname;
    if (pathname !== '' && pathname[0] !== '/') {
      pathname = `/${pathname}`;
    }
    this.keycloak.logout({ redirectUri: window.location.origin + pathname });
  }

  get isTokenExpired() {
    return this.keycloak.isTokenExpired(this.minValidity);
  }

  updateToken() {
    return this.keycloak.updateToken(this.minValidity);
  }

  tokenAsync() {
    if (this.keycloak.isTokenExpired(this.minValidity)) {
      return this.updateToken().then(() => this.keycloak.token!);
    } else {
      return Promise.resolve(this.keycloak.token!);
    }
  }

  get fullName() {
    if (!this.keycloak || !this.keycloak.idTokenParsed) {
      return undefined;
    }

    const idToken = this.keycloak.idTokenParsed as Record<string, any>;
    const firstName = idToken.given_name as string;
    let lastName = idToken.family_name as string;
    lastName = lastName === '-' ? '' : lastName; // handle photographers that have "-" as lastName

    if (!lastName || firstName.includes(lastName)) {
      return firstName;
    }

    return `${firstName} ${lastName}`;
  }

  get email() {
    if (!this.keycloak || !this.keycloak.idTokenParsed) {
      return undefined;
    }

    const idToken = this.keycloak.idTokenParsed as Record<string, any>;
    return idToken.email as string;
  }

  get realm() {
    return this.keycloak.realm;
  }
}

export let authenticationService: AuthenticationService;

export function createAuthenticationService({ url, realm, clientId }: KeycloakConfig) {
  authenticationService = new AuthenticationService({ url, realm, clientId });
  return authenticationService;
}
