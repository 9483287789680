import { createAuthenticationService } from './AuthenticationService';
import { fetchWebSiteConfiguration } from './fetchWebSiteConfiguration';

const APPSETTINGS_JSON = `${process.env.PUBLIC_URL}/appsettings.json?v=${Date.now()}`;

async function fetchIdentityInformation(endpointApi, token) {
  const response = await fetch(endpointApi, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      query: `{
        viewer {
          identityInformation {
            primaryShopId
          }
        }
      }`,
    }),
  });

  return response.json();
}

async function run() {
  try {
    window.MW_APP_SETTINGS = await (
      await fetch(APPSETTINGS_JSON, {
        credentials: 'include',
      })
    ).json();

    const constants = await import(/* webpackChunkName: "constants" */ './Constants');
    const {
      AUTH_URL,
      AUTH_REALM,
      AUTH_CLIENT_ID,
      BASE_URL_API,
      ENDPOINT_API,
      SETTINGS_PREFIX,
      updateDefaultShop,
      updateSettingsPrefix,
    } = constants;

    const authenticationService = createAuthenticationService({
      url: AUTH_URL,
      realm: AUTH_REALM,
      clientId: AUTH_CLIENT_ID,
    });

    await authenticationService.init();
    const token = await authenticationService.tokenAsync();

    const [{ data }, webSiteConfiguration] = await Promise.all([
      fetchIdentityInformation(ENDPOINT_API, token),
      fetchWebSiteConfiguration(BASE_URL_API, token),
    ]);

    updateDefaultShop(data.viewer.identityInformation.primaryShopId);
    updateSettingsPrefix(SETTINGS_PREFIX + data.viewer.identityInformation.primaryShopId + '.');

    const index2 = await import(/* webpackChunkName: "index2" */ './index2');
    index2.default(webSiteConfiguration);
  } catch (e) {
    if (
      e instanceof TypeError &&
      (e.message === 'Failed to fetch' || e.message === 'NetworkError when attempting to fetch resource')
    ) {
      return;
    }
    console.error('initialization failed', e);
  }
}

run();
