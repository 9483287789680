// Be careful importing modules here, this is used in the index.js chunk and we want to avoid unneeded transitive dependencies !!!!

export async function fetchWebSiteConfiguration(baseUrlApi: string, token: string) {
  const response = await fetch(`${baseUrlApi}/WebSiteConfiguration/`, {
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.json();
}
